import axios from 'axios';
import { ILead } from '../store/LeadContext';
// import moment from 'moment'

enum OrgTemplateIds {
  NY = '93ff22ae-078a-4fd9-8871-82cf89535f48',
  NJ = 'b4208ff2-3323-4773-9f43-718e68edf390',
  CT = '50a05585-2f9c-44fd-a8e1-d9f264383f96',
  IL = 'f661a0ee-7d00-4d02-83f2-496355b0f9e0',
}
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,

  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

// ========= [ POST Ticket ] ========= //
export const postTicket = async (lead: Partial<ILead>) => {
  const url = '/office-visit/verify-existence';
  const documentToSend = lead.tlcNumber ? { tlcNumber: lead.tlcNumber } : { driversLicenseNumber: lead.driversLicenseNumber };
  try {
    const { data } = await api.post(url, {
      email: lead.email,
      formTemplateId: OrgTemplateIds[lead.location as keyof typeof OrgTemplateIds],
      location: lead.location,
      reasonForVisit: lead.reasonForVisit,
      ...documentToSend,
    });
    return data;
  } catch (error) {
    return { error };
  }
};

// ========= [ POST New Customer Ticket ] ========= //
export const postNewCustomer = async (lead: Partial<ILead>) => {
  const url = '/office-visit';
  try {
    const { data } = await api.post(url, {
      utm: lead.utm || 'WB',
      form: lead.form || '',
      email: lead.email,
      location: lead.location,
      formTemplateId: OrgTemplateIds[lead.location as keyof typeof OrgTemplateIds],
      firstName: lead.firstName,
      lastName: lead.lastName,
      gender: lead.gender,
      licenseNumber: lead.driversLicenseNumber,
      TLCNumber: lead.tlcNumber,
      phoneNumber: lead.phoneNumber,
      dateOfBirth: lead.dateOfBirth,
      reasonForVisit: lead.reasonForVisit,
      browserDevice: lead.browserDevice,
      browserLanguage: lead.browserLanguage,
      preferredLanguage: lead.preferredLanguage,
      formVariant: lead.formVariant,
      isDelivery: lead.delivery,
    });
    return data;
  } catch (e) {
    console.log('error:', e);
  }
};

export const getTicket = async (id: string) => {
  const url = `/office-visit/public/${id}`;
  return (await api.get(url)).data;
};
