import { Navigate, useParams } from 'react-router-dom';

const ORGANIZATIONS = ['NY', 'NJ', 'CT', 'IL'];

export default function PageByOrgRenderer({ children, path }: { children?: React.ReactElement; path?: string }) {
  const params = useParams();

  if (params && !ORGANIZATIONS.includes(params.org as string)) {
    return <Navigate to="/" />;
  }

  if (children) return children as React.ReactElement;
  return <Navigate to={path as string} />;
}
